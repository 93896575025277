<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :hide-edit="true" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> User Id </v-list-item-title>
            <v-list-item-subtitle v-if="item.userId" class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item.userId?._id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else> N/A </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Username </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.displayName)"
              >{{ item.displayName }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> First name </v-list-item-title>
            <v-list-item-subtitle> {{ item.firstName }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Last name </v-list-item-title>
            <v-list-item-subtitle> {{ item.lastName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />

        <vx-card-list title="Contact" :hide-edit="true" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Email </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.email)">
              {{ item.email }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Phone </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.phone)">
              {{ item.phone }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <!-- <v-card v-if="item.userId" class="mb-4">
          <v-card-title class="primary--text">
            Photo
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <vx-img width="200px" auto :src="$getUserImage(item, '400')" />
          </v-card-text>
        </v-card> -->

        <vx-card-list v-if="item" title="Other Info" :hide-edit="true" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Total Spent </v-list-item-title>
            <v-list-item-subtitle> {{ item.totalSpent | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> # of completed bookings </v-list-item-title>
            <v-list-item-subtitle> {{ item.completedBookings }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.lastEnquiryId">
            <v-list-item-title> Last enquiry date </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.lastEnquiryId.startDate | dateMonthYear(item.lastEnquiryId.timezone) }}
              {{ item.lastEnquiryId.startDate | time(item.lastEnquiryId.timezone) }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.userId" title="Stats" :hide-edit="true" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Last logged in </v-list-item-title>
            <v-list-item-subtitle v-if="item.stats"> {{ item.stats.lastLoggedIn | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Signed up date </v-list-item-title>
            <v-list-item-subtitle> {{ item.createdAt | date }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>
      <v-col cols="12" sm="6">
        <vx-card-list title="References" :item="item" hide-edit>
          <v-list-item :to="$toList('account', { _id: item.accounts?.map(acc => acc._id) })">
            <v-list-item-title> Profiles </v-list-item-title>
            <v-list-item-subtitle> {{ count.profiles }} </v-list-item-subtitle>
          </v-list-item>

          <!-- <v-list-item :to="$toList('account', { _id: item.hostAccount })">
            <v-list-item-title> Host Profile </v-list-item-title>
            <v-list-item-subtitle> {{ count.hostProfiles }} </v-list-item-subtitle>
          </v-list-item> -->

          <v-list-item :to="$toList('booking', { contactId: item._id })">
            <v-list-item-title> Bookings </v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings }} </v-list-item-subtitle>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title> Connected All Host Profiles </v-list-item-title>
            <v-list-item-subtitle> {{ count.hostProfiles }} </v-list-item-subtitle>
          </v-list-item> -->
        </vx-card-list>

        <vx-card-list v-if="(item.secondaryEmails || []).length" title="Secondary Emails" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.secondaryEmails" :key="index" dense>
            <v-list-item>
              <v-list-item-title> Name </v-list-item-title>
              <v-list-item-subtitle> {{ info.firstName }} {{ info.lastName }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Email </v-list-item-title>
              <v-list-item-subtitle> {{ info.email }} </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Notification </v-list-item-title>
              <v-list-item-subtitle class="d-flex">
                <div v-for="(value, name) in info.notification" :key="name" class="mr-2">
                  {{ name }}: {{ value ? 'Yes' : 'No' }}&nbsp;
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="index !== item.secondaryEmails.length - 1" />
          </v-list>
        </vx-card-list>

        <vx-card-list v-if="(item.secondaryPhones || []).length" title="Secondary Phones" :item="item" :tab="0">
          <v-list v-for="(info, index) in item.secondaryPhones" :key="index" dense>
            <v-list-item>
              <v-list-item-title> Name </v-list-item-title>
              <v-list-item-subtitle> {{ info.firstName }} {{ info.lastName }} </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title> Phone </v-list-item-title>
              <v-list-item-subtitle> {{ info.phone }} </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Notification </v-list-item-title>
              <v-list-item-subtitle class="d-flex">
                <div v-for="(value, name) in info.notification" :key="name" class="mr-2">
                  {{ name }}: {{ value ? 'Yes' : 'No' }}&nbsp;
                </div>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider v-if="index !== item.secondaryPhones.length - 1" />
          </v-list>
        </vx-card-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ContactService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getContact();
    this.getCount();
  },
  methods: {
    async getContact() {
      const response = await ContactService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.displayName);
      }
    },
    async getCount() {
      const response = await ContactService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
