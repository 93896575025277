var render = function () {
  var _vm$item$userId, _vm$item$accounts;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "hide-edit": true,
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" User Id ")]), _vm.item.userId ? _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$item$userId = _vm.item.userId) === null || _vm$item$userId === void 0 ? void 0 : _vm$item$userId._id) + " ")]) : _c('v-list-item-subtitle', [_vm._v(" N/A ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Username ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.displayName);
      }
    }
  }, [_vm._v(_vm._s(_vm.item.displayName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" First name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.firstName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Last name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.lastName) + " ")])], 1)], 1), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  }), _c('vx-card-list', {
    attrs: {
      "title": "Contact",
      "hide-edit": true,
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Email ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.email);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.email) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Phone ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.phone);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.phone) + " ")])], 1)], 1), _vm.item ? _c('vx-card-list', {
    attrs: {
      "title": "Other Info",
      "hide-edit": true,
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Total Spent ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(_vm.item.totalSpent)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of completed bookings ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.completedBookings) + " ")])], 1), _vm.item.lastEnquiryId ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Last enquiry date ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateMonthYear")(_vm.item.lastEnquiryId.startDate, _vm.item.lastEnquiryId.timezone)) + " " + _vm._s(_vm._f("time")(_vm.item.lastEnquiryId.startDate, _vm.item.lastEnquiryId.timezone)) + " ")])], 1) : _vm._e()], 1) : _vm._e(), _vm.item.userId ? _c('vx-card-list', {
    attrs: {
      "title": "Stats",
      "hide-edit": true,
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Last logged in ")]), _vm.item.stats ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.stats.lastLoggedIn)) + " ")]) : _vm._e()], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Signed up date ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("date")(_vm.item.createdAt)) + " ")])], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('account', {
        _id: (_vm$item$accounts = _vm.item.accounts) === null || _vm$item$accounts === void 0 ? void 0 : _vm$item$accounts.map(function (acc) {
          return acc._id;
        })
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Profiles ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.profiles) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('booking', {
        contactId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Bookings ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookings) + " ")])], 1)], 1), (_vm.item.secondaryEmails || []).length ? _c('vx-card-list', {
    attrs: {
      "title": "Secondary Emails",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.secondaryEmails, function (info, index) {
    return _c('v-list', {
      key: index,
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.firstName) + " " + _vm._s(info.lastName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Email ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.email) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Notification ")]), _c('v-list-item-subtitle', {
      staticClass: "d-flex"
    }, _vm._l(info.notification, function (value, name) {
      return _c('div', {
        key: name,
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(name) + ": " + _vm._s(value ? 'Yes' : 'No') + "  ")]);
    }), 0)], 1), index !== _vm.item.secondaryEmails.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 1) : _vm._e(), (_vm.item.secondaryPhones || []).length ? _c('vx-card-list', {
    attrs: {
      "title": "Secondary Phones",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.secondaryPhones, function (info, index) {
    return _c('v-list', {
      key: index,
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.firstName) + " " + _vm._s(info.lastName) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Phone ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(info.phone) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Notification ")]), _c('v-list-item-subtitle', {
      staticClass: "d-flex"
    }, _vm._l(info.notification, function (value, name) {
      return _c('div', {
        key: name,
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(name) + ": " + _vm._s(value ? 'Yes' : 'No') + "  ")]);
    }), 0)], 1), index !== _vm.item.secondaryPhones.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 1) : _vm._e()], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }